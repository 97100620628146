import { useState } from "react";
import parallaxAstronauta from "../asset/parallax/parallaxAstronauta.png"; 
import parallaxEstrella from "../asset/parallax/parallaxEstrella.jpg";  
import parallaxLunaInferior from "../asset/parallax/parallaxLunaInferior.png";  
import parallaxLunaIzquierda from "../asset/parallax/parallaxLunaIzquierda.png";  

export const Parallax =()=>{
  const [bg, setBg] = useState(20);
  return(
    <div className="m_parallax_session_outer">
      <div className="m_parallax_session">
        <img className="ñimg1" src={parallaxAstronauta} alt="" />
        <img className="ñimg2" src={parallaxLunaInferior} alt="" />
        <img className="ñimg3" src={parallaxLunaIzquierda} alt="" />
      </div>
    </div>
  )
}