export const ButtonBlank = ({ linkBlank, textButton }) => {
  return (
      <button className="me_button w-100">
          <span className="me_button_lg">
              <span className="me_button_sl"></span>
              <span className="me_button_text">
                  <a href={linkBlank} target="_blank">
                     {textButton}
                  </a>
              </span>
          </span>
      </button>
  );
};
