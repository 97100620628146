import './App.css';
import 'bootstrap/dist/css/bootstrap.min.css'; // Importa Bootstrap
import { Route, Routes } from 'react-router-dom';

// componentes 
import { Home } from './pages/Home';
import { Error404 } from './pages/Error404';
import { NotasPages } from './pages/NotasPages';
import LoginForm from './pages/Login';
import { AdminDashboard } from './pages/Admin';
import { Footer } from './componentes/Footer';
import { ParkSession } from './pages/ParkSession';
import { Nav, NavBurger } from './componentes/Nav';
import { NotasLeer } from './pages/NotasLeer';
import { NavProgramacion, ReproductorFixedTopAll } from './componentes/Reproductor';
import ScrollToTop from './componentes/ScrollTop';
import { Parallax } from './pages/Parallax';


function App() {
  return (
    <div className="App bg-black">
      <ScrollToTop />
      <ReproductorFixedTopAll />
      <NavProgramacion />
      <NavBurger />
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/ParkSession" element={<ParkSession />} />
        <Route path="/notas" element={<NotasPages />} />
        <Route path="/login" element={<LoginForm />} />
        <Route path="/dashboard" element={<AdminDashboard />} />
        <Route path="/notas/:id" element={<NotasLeer />} />
        <Route path="/parallax" element={<Parallax />} />
        <Route path="*" element={<Error404 />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
